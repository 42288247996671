import { Icon } from '@iconify/react'
import { Link, navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { RepoIconMap, RepoMap } from '../../../../components/RepoMap'
import axios from 'axios'
import { getUrl } from 'API/getUrl'
import { getDefaultOptions } from 'API/auth'
import { useAppDispatch } from 'redux/hooks'
import { setUpdate } from 'redux/slices/ApplicationState'
import { ActivityType } from './index'
import { getCBME } from '../../../../API/getCBME'
import { FaCheckCircle } from 'react-icons/fa'
export function ActivityComponent({
	course_id,
	idx,
	elem,
	viewAs,
	activity_status,
}: {
	course_id: string
	idx: number
	moduleAvailableTill: number
	elem: ActivityType
	viewAs: string
	activity_status: any
}): JSX.Element {
	const dispatch = useAppDispatch()
	const [redirectLink, setRedirectLink] = useState<string>('')
	const [done, setDone] = useState(false)
	useEffect(() => {
		if (elem) {
			const redirectLink = './' + RepoMap[elem.__t] + (elem?.video ? '-video' : '') + '/' + elem.uid
			setRedirectLink(redirectLink)
		}
	}, [elem])

	useEffect(() => {
		if (activity_status && viewAs === 'Student') {
			activity_status.filter((activity: any) => {
				if (activity.uid === elem.uid) {
					setDone(activity.submitted)
				}
			})
		}
	}, [activity_status, viewAs])
	console.log(elem, viewAs)
	if (elem.non_assignment_type) console.log(elem.non_assignment_type, elem.name, elem.module)
	return (
		<>
			<div key={idx} className='media pt-4 pb-1'>
				<div className='row'>
					<div className='col-4 col-sm-2'>
						<img className='pl-1 px-md-4 my-auto streamIcon' src={(RepoIconMap as any)[elem.__t]} alt='' />
					</div>
					<div className='col-6 col-sm-8'>
						<h6 className='mediaTitle pl-1 pl-md-0'>
							<>
								<Link to={redirectLink}>{elem.name ?? (elem as any)?.heading}</Link>
							</>
						</h6>
						<p className='pl-1 pl-md-0'>
							<b>Available from:</b>{' '}
							{new Date(elem.available_from).toLocaleString(undefined, {
								weekday: 'long',
								year: 'numeric',
								month: 'long',
								day: 'numeric',
								hour12: true,
								hour: 'numeric',
								minute: 'numeric',
							})}
						</p>

						<p>
							{elem.proctor === true && (
								<span
									onClick={() => {
										navigate(redirectLink)
									}}
									className='btn btn-sm btn-rounded btn-primary'
								>
									{'EXAMINATION'}
								</span>
							)}
							{(elem.proctor === false || elem.proctor === undefined) && (
								<span
									onClick={() => {
										navigate(redirectLink)
									}}
									className='btn btn-sm btn-rounded btn-primary'
								>
									{elem?.non_assignment_type?.name
										? elem?.non_assignment_type?.name?.toUpperCase()
										: RepoMap[elem.__t].toUpperCase()}
								</span>
							)}
							{elem.__t === 'LectureRepository' && getCBME() && (
								<>
									<span className='padding' style={{ padding: '10px' }}></span>
									<span className='btn btn-sm btn-rounded btn-warning'>{`${elem?.lecture_type?.name ?? 'General'}`}</span>
								</>
							)}
							<span className='padding' style={{ padding: '10px' }}></span>
							{elem.module && <span className='btn btn-sm btn-rounded btn-success'>{`${elem.module.index}. ${elem.module.name}`}</span>}
							{done && viewAs === 'Student' && (
								<span className='padding' style={{ marginLeft: '10px' }}>
									<FaCheckCircle size={25} style={{ color: 'green' }} />
								</span>
							)}
						</p>
					</div>

					<div className='col-2'>
						<div className='row'>
							{viewAs === 'Teacher' && (
								<div className='col-6'>
									<span className='float-right'>
										<Dropdown>
											<Dropdown.Toggle variant='white' className='no-hover no-shadow fileActions'>
												<Icon icon='bx:bx-dots-vertical-rounded' className='bx-sm float-right'></Icon>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item
													onClick={() => {
														if (elem.__t === 'QuizRepository' && elem.proctor === true) {
															navigate('../classwork/add/' + 'proctor' + '?edit=true&elem_id=' + elem.uid)
														} else {
															navigate('../classwork/add/' + RepoMap[elem.__t] + '?edit=true&elem_id=' + elem.uid)
														}
													}}
												>
													<span>
														<Icon icon='bx:bxs-edit' />
													</span>
													<span className='ml-2'>Edit</span>
												</Dropdown.Item>

												<Dropdown.Item
													onClick={async (e) => {
														e.preventDefault()
														const c1 = confirm(
															`Are you sure you want to delete the asset ${elem.name ?? (elem as any)?.heading}`
														)
														if (c1) {
															const c2 = confirm(
																`Are you sure you want to delete ${
																	elem.name ?? (elem as any)?.heading
																}? This action is irreversible.`
															)
															if (c2) {
																try {
																	await axios.delete(
																		getUrl(
																			`asset/delete/${RepoMap[elem.__t]}?elem_id=${
																				elem.uid
																			}&course=${course_id}`
																		),
																		getDefaultOptions()
																	)
																	dispatch(setUpdate())
																	window?.location?.reload()
																} catch (err) {
																	console.error(err)
																}
															}
														}
													}}
												>
													<Icon icon='ic:baseline-delete' />
													<span className='ml-2'>Delete</span>
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
